import Cart from './../modules/cart';
import Utilities from './../modules/utilities';

/**
 * Site navigation
 */

class Navigation {
  static MobileNavigationToggleSelector = '.js-site-nav-toggler';
  static SearchToggleSelector = '.js-mobile-search-link';

  static init(): void {
    const mobileNavToggleLink: HTMLElement = document.querySelector(Navigation.MobileNavigationToggleSelector);

    // setup sticky - wait to make sure banner has been loaded
    setTimeout(() => { Navigation.setupStickyNav(); }, 100);

    let clickEventType = 'click';

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      clickEventType = 'touchstart';
    }

    // toggle mobile navigation
    mobileNavToggleLink?.addEventListener(clickEventType, (e: Event) => {
      Navigation.handleToggleMobileNavigationClick(e);
    }, { 'passive': true });

    // Defer work that isn't user-visible to a separate task:
    setTimeout(() => {
      // push new artist sign up event
      this.setupSearchBarEvents();
    }, 0);
  }

  /**
   * Set focus in input when searchbar is displayed
   */
  static setupSearchBarEvents(): void {
    const collapse: HTMLElement = document.getElementById('mobileSearchBoxCollapse');
    const input: HTMLInputElement = document.querySelector('.js-mobile-search-input');
    const siteSearchForm: HTMLFormElement = document.querySelector('.js-site-search-form');

    collapse?.addEventListener('shown.bs.collapse', () => {
      input.focus();

      // add form submit event listener
      siteSearchForm.addEventListener('submit', () => {
        siteSearchForm.classList.add('is-searching');
      }, true);
    });
  }

  /**
   * Toggle mobile navigation as modal
   * @param e - toggle click event (passive)
   */
  static handleToggleMobileNavigationClick(e: Event): void {
    const toggleLinkElement: HTMLElement = e.currentTarget as HTMLElement;

    const navContainer: HTMLElement = document.querySelector('#siteNavigation');
    const body: HTMLElement = document.querySelector('body');

    if (!toggleLinkElement.classList.contains('is-active')) {
      if (Cart.MiniCartContainer.classList.contains('is-open')) {
        Cart.miniCartToggle();
      }

      toggleLinkElement.classList.add('is-active');
      navContainer.classList.add('d-block');

      // body not scrollable while nav is open
      body.style.overflowY = 'hidden';

      // timeout for fade transition
      setTimeout(() => {
        navContainer.classList.add('is-open');
      }, 150);
    } else {
      toggleLinkElement.classList.remove('is-active');
      navContainer.classList.remove('is-open', 'd-block');
      body.style.overflowY = 'auto';
    }
  }

  /**
   * Set sticky class
   */
  static setStickyClass(): void {
    const siteHeader: HTMLElement = document.querySelector('#head');

    if (window.scrollY > 0) {
      if (!siteHeader?.classList.contains('is-sticky')) {
        siteHeader?.classList.add('is-sticky');
      }
    }
    else {
      siteHeader?.classList.remove('is-sticky');
    }
  }

  /**
   * Setup sticky site header on scroll
   */
  static setupStickyNav(): void {
    const siteHeader: HTMLElement = document.querySelector('#head');
    const banner: HTMLElement = document.querySelector('.js-alert');

    const initialOffsetTop = banner ? banner.offsetHeight : 0;

    if (window.scrollY > initialOffsetTop) {
      siteHeader.classList.add('is-sticky');
    }

    // debounced scroll event
    const debouncedScroll = Utilities.debounce(this.setStickyClass, 10);
    window.addEventListener('scroll', debouncedScroll);
  }

}

export default Navigation;
