import { Addons, ApiEndpoints, ProductType, Margin, AddonType } from '../enums';
import Cart from './cart';
import UrlService from './url-service';
import Utilities from './utilities';
import GalleryWall from './gallery-wall';
import Ecommerce from './ecommerce';
import Carousel from './carousel';

/**
 * Product selector
 */
class ProductSelector {
  static AddonsAndOptionsContainerSelector = '.js-addons-and-options';
  static AddonsContainerSelector = '.js-addons-container';
  static ProductSelectContainerSelector = '.js-product-select';
  static ProductSelectBoxSelector = '.js-product-select-box';
  static ProductMarginToggleSelector = '.js-margins-group';
  static ProductAddonsSelector = '.js-addons-select';
  static SubmitButtonSelector = '.js-add-to-cart-btn';
  // static PosterSelector = '.js-poster-image';
  static ArtworkSelector = '.js-mobile-poster-image';
  static MobilePosterPremiumSelector = '.js-mobile-poster-image-premium';
  static ProductGroupSelector = '.js-product-group';
  static FrameGroupSelector = '.js-frame-group';
  static FrameGroupPremiumSelector = '.js-frame-group-premium';
  static PassePartoutSelector = '.js-passepartout-prd';
  static HeadPriceSelector = '.js-product-selector-head-price';
  static Poster: HTMLElement = document.querySelector(ProductSelector.ArtworkSelector);
  static PosterWrapper: HTMLElement = ProductSelector.Poster?.parentElement;
  static AddonsCssClasses: Array<string> = [Addons.BlackFrame, Addons.WhiteFrame, Addons.OakFrame, Addons.WalnutFrame, Addons.Passpartout, Addons.RokokoFrame];
  /**
   * Initialize
   */
  static init(): void {
    // setup smooth scroll links (product info links)
    Utilities.setupSmoothScrollLinks();

    // setup select product size event
    this.setupProductGroupEvents();

    // setup select frame event
    this.setupFrameGroupEvents();

    // setup margin selection
    this.setupMarginSwitchClickEvent();

    // setup passepartout selection
    this.setupPasspartoutEvent();

    // setup addon selection TODO: might not be needed anymore
    this.setupAddonsSelectChangeEvent();

    // setup accessories select
    this.setupSelectBoxProductSizeEvents();

    // setup any pre-selected addons
    this.setupPreSelectedAddons();

    // setup any pre-selected products
    this.setupPreSelectedProduct();

    // setup any preselcted size
    this.setupPreSelectedSize();

    // setup product tabs navigation events
    this.setupProductTabsEvents();

    // setup initial add to cart buttons for addons
    Cart.setupAddToCartButtonsEvent();
  }

  /**
   * Setup events for the product tabs navigation
   */
  static setupProductTabsEvents(): void {
    const tablinks: NodeListOf<HTMLLinkElement> = document.querySelectorAll('#productSelectorTabs .nav-link');

    tablinks.forEach((tab) => {
      tab.addEventListener('shown.bs.tab', () => {
        const productType = tab.dataset.productType;
        const paneSelector = tab.getAttribute('href');
        const pane = document.querySelector(paneSelector);
        const defaultSizeTrigger: HTMLElement = pane.querySelector('.js-product-group-btn:checked');
        
        // trigger default size click to async load addons and options
        defaultSizeTrigger.click();

        // initiate product type slideshow
        this.setupProductSlideshows(productType as ProductType);

        // edit product title prefix to reflect current product type
        this.setProductTitlePrefix(productType);

        // hide canvas shadow in safari
        if (productType.toLowerCase() === ProductType.Canvas.toLowerCase()) {
          if (Utilities.isSafariDesktop()) {
            this.PosterWrapper.classList.add('hide-pseudo-shadow');
          }
        }
      });
    });
  }

  /**
   * Init slideshow based on product type
   * @param productType - the type of slideshow to init
   */
  static setupProductSlideshows(productType = `${ProductType.Poster}`): void {
    productType = productType.toLowerCase();

    // framed fine-art
    const fineArtSlideShowSelector = '.js-clean-product-slideshow-fineart';
    const fineArtSlideshow: HTMLElement = document.querySelector(fineArtSlideShowSelector);
    // posters
    const artPrintSlideshow: HTMLElement = document.querySelector('.js-clean-product-slideshow');
    // canvas
    const canvasSlideShowSelector = '.js-clean-product-slideshow-canvas';
    const canvasSlideshow: HTMLElement = document.querySelector(canvasSlideShowSelector);

    if (productType === ProductType.FramedArtPrint.toLowerCase()) { // FRAMED FINE ART
      this.PosterWrapper.classList.remove('is-canvas');
      this.Poster.classList.remove('is-canvas');

      // display carousel
      fineArtSlideshow?.classList.remove('d-none');
      artPrintSlideshow?.classList.add('d-none');
      canvasSlideshow?.classList.add('d-none');
    }
    else if (productType === ProductType.Poster.toLowerCase()) { // POSTER
      this.PosterWrapper.classList.remove('is-canvas');
      this.Poster.classList.remove('is-canvas');

      // display carousel
      artPrintSlideshow?.classList.remove('d-none');
      fineArtSlideshow?.classList.add('d-none');
      canvasSlideshow?.classList.add('d-none');
    }
    else if (productType === ProductType.Canvas.toLowerCase()) { // CANVAS
      // set canvas css

      this.Poster = document.querySelector('.js-mobile-poster-image-canvas');

      this.Poster.classList.remove('has-margin');
      this.Poster.classList.remove('has-ppt');

      this.Poster.classList.forEach(className => {
        if (className.startsWith('m-frame')) {
          this.Poster.classList.remove(className);
        }
      });

      this.PosterWrapper.classList.add('is-canvas');
      this.Poster.classList.add('is-canvas');

      // display carousel
      fineArtSlideshow?.classList.add('d-none');
      artPrintSlideshow?.classList.add('d-none');
      canvasSlideshow?.classList.remove('d-none');
    }

    // refresh carousel
    Carousel.refreshProductSlideshow();
  }

  /**
   Set product title prefix
  */
  static setProductTitlePrefix(productType = ProductType.Poster.toLowerCase()): void {
    const prefix: HTMLElement = document.querySelector('.js-product-title-prefix');

    if (productType === ProductType.FramedArtPrint.toLowerCase()) {
      prefix.innerHTML = 'Fine Art:';
    }
    else if (productType === ProductType.Canvas.toLowerCase()) {
      prefix.innerHTML = 'Canvas:';
    }
    else if (productType === ProductType.Poster.toLowerCase()) {
      prefix.innerHTML = 'Poster:';
    }
    else {
      prefix.innerHTML = productType;
    }
  }

  /**
   * Set selected frame if pre-selected via query parameter
   */
  static setupPreSelectedAddons(): void {
    const frame = Utilities.getParameterValue('frame');

    if (frame && frame != 'hide') {
      const frameLabelCapitalized = `${frame.charAt(0).toUpperCase() + frame.slice(1)}`;
      const frameValue = `${frameLabelCapitalized}Frame`;

      const btn: HTMLElement = document.querySelector(`.js-frame-group-btn[data-type="${frameValue}" i]`);

      btn.click();

      // we slide one step to be sure the selected frame is displayed
      Carousel.AddonProductsSlider.goTo('next');
    }
  }

 /**
 * Set selected product if pre-selected via query parameter
 */
  static setupPreSelectedProduct(): void {
    const productType = Utilities.getParameterValue('prd');

    if (productType) {
      const btn: HTMLElement = document.querySelector(`[data-product-type="${productType}" i]`); // the 'i' is making the comparison case insensitive

      if (btn) {
        btn.click();
      }
    }
  }

  /**
   * Set selected poster size via query parameter
   */
  static setupPreSelectedSize(): void {
    const size = Utilities.getParameterValue('size');
    const productType = Utilities.getParameterValue('prd');

    if (productType && size) {
      const btn: HTMLElement = document.querySelector(`input[data-product-size="${size}"][data-type="${productType}" i]`); // the 'i' is making the comparison case insensitive
      if (btn) {
        btn.click();
      }
    }
    else if (size) {
      const btn: HTMLElement = document.querySelector(`input[data-product-size="${size}" i]`); // the 'i' is making the comparison case insensitive
      if (btn) {
        btn.click();
      }
    }
  }

  /**
   * Setup product group buttons events - product size buttons
   */
  static setupProductGroupEvents(): void {
    const productGroupElements: NodeListOf<HTMLElement> = document.querySelectorAll(this.ProductGroupSelector);

    if (!productGroupElements || productGroupElements.length === 0) {
      return;
    }

    productGroupElements.forEach(prdGroup => {
      const radios: NodeListOf<HTMLElement> = prdGroup.querySelectorAll('.js-product-group-btn');

      radios.forEach(btn => {
        // on click fetch options and set price
        btn.addEventListener('click', () => {
          const productId = btn.dataset.value;
          const productType = btn.dataset.type?.toLowerCase();
          const form = btn.closest('form');
          const submitBtn: HTMLElement = form.querySelector(ProductSelector.SubmitButtonSelector);
          let currentPhotoId = 0;
          const isWallPage = document.querySelector('.js-gallery-wall');

          // if gallery wall page - no submit button exists in product selector form
          if (isWallPage) {
            const photoIdInput: HTMLInputElement = form.querySelector('input[name="photoId"]');
            currentPhotoId = +photoIdInput.value;
          }
          else {
            currentPhotoId = +submitBtn.dataset.photo ?? 0;

            // setup datalayer data if not wall page
            this.setupDatalayerDetails(btn);
          }

          // get switch elm before applying response
          const marginToggleGroup = form.querySelector(ProductSelector.ProductMarginToggleSelector);
          const currentMarginElement: HTMLInputElement = marginToggleGroup?.querySelector('input[name="options[Margin]"]:checked');

          // disable until finished fetching addons
          submitBtn?.setAttribute('disabled', 'disabled');

          // fetch addons
          ProductSelector.fetchAddonsAndOptions(+productId, +currentPhotoId).then(response => {
            const addonsAndOptions = form.querySelector(ProductSelector.AddonsAndOptionsContainerSelector);
            addonsAndOptions.innerHTML = response;

            // set selected margin value
            const newMarginElement: HTMLInputElement = form.querySelector(ProductSelector.ProductMarginToggleSelector);
            newMarginElement.checked = currentMarginElement?.checked;

            if (isWallPage) {
              GalleryWall.setupMarginSelectEvents();
            }

            // POSTER EVENTS - add styles on addon changes etc
            if (productType == null || productType === ProductType.Poster.toLowerCase()) {
              // apply margin class if selected
              if (newMarginElement.checked) {
                const posterElement = ProductSelector.Poster;
                posterElement?.classList.add(Addons.Margin);
              }

              // only activate change events (image css classes) if not in edit view
              if (!addonsAndOptions.classList.contains('no-change-events')) {
                // re-initiate event listeners on dynamically created elements
                ProductSelector.setupMarginSwitchClickEvent(form);
                ProductSelector.setupAddonsSelectChangeEvent();

                // bootstrap native init to make addons collapse work
                window.BSN.initCallback();

                // trigger change on addons when switching product type
                const addonsSelect: HTMLSelectElement = document.querySelector(ProductSelector.ProductAddonsSelector);
                addonsSelect?.dispatchEvent(new Event('change'));
              }

              ProductSelector.setInitialPreviewValues();
              ProductSelector.setupFrameGroupEvents();
              ProductSelector.setupPasspartoutEvent(form);

              // re-init slider
              Carousel.initAddonSwiper();
            }

            // FRAMED ART PRINTS EVENTS (Fine art)
            if (productType === ProductType.FramedArtPrint.toLowerCase()) {

              // only activate change events (image css classes) if not in edit view
              if (!addonsAndOptions.classList.contains('no-change-events')) {
                // re-initiate event listeners on dynamically created elements
                ProductSelector.setupMarginSwitchClickEvent(form);
                ProductSelector.setupPasspartoutEvent(form);

                // re-init Bootstrap Native - product group buttons init
                window.BSN.initCallback();
              }

              ProductSelector.setInitialPreviewValues(true); // pass true for fine art
              ProductSelector.setupFrameGroupEvents();

              // trigger default addon/frame click to set correct preview styles and prices
              const defaultTrigger: HTMLElement = form.querySelector('.js-frame-group-btn:checked');

              defaultTrigger.click();
            }

            // ADDONS ADD TO CART BUTTONS
            Cart.setupAddToCartButtonsEvent();

            // re-activate
            submitBtn?.removeAttribute('disabled');

            // re-init Bootstrap Native - product group buttons init
            window.BSN.initCallback();
          });

          // set price
          const productPrice = btn.dataset.price;
          ProductSelector.setPrice(productPrice, productType, btn);

          // set sale price if discount is active
          try {
            if (+btn.dataset.priceValue > +btn.dataset.discountPriceValue.replace(',', '.')) {
              ProductSelector.setSalePrice(btn.dataset.discountPriceValue, productType, btn);
            }
          } catch { Utilities.log('Error: Could not calculate discounted price'); }
        }, true);
      });
    });
  }

  /**
   * Setup frame group buttons events - frame color buttons
   */
  static setupFrameGroupEvents(): void {
    const groupSelector = this.FrameGroupSelector;
    const frameGroupElements: NodeListOf<HTMLElement> = document.querySelectorAll(groupSelector);

    if (!frameGroupElements || frameGroupElements.length === 0) {
      return;
    }

    frameGroupElements.forEach(frameGroup => {
      const radioSelector = '.js-frame-group-btn';
      const radios: NodeListOf<HTMLElement> = frameGroup.querySelectorAll(radioSelector);

      radios.forEach((btn: HTMLInputElement) => {

        // on click set style
        btn.addEventListener('click', () => {
          const form: HTMLElement = frameGroup.closest('form'); // fetch closest form to determine if fine-art or poster
          this.setAddonStyles(btn.getAttribute('data-type'), form.classList.contains('js-add-to-cart-form-premium'));
          this.setAddonPrice(btn.getAttribute('data-price'), btn.getAttribute('data-type'));
          this.setAddonOriginalPrice(btn.getAttribute('data-original-price'), btn.getAttribute('data-type'));
          // update total price
          this.updateTotalPrice(btn);
        }, true);
      });
    });
  }

  /**
   * Setup passepartout option click event
   * If container is set, it will only apply to the given container
   */
  static setupPasspartoutEvent(container: HTMLElement = null): void {
    let pptElement: HTMLElement = document.querySelector(ProductSelector.PassePartoutSelector);

    if (container != null) {
      pptElement = container.querySelector(ProductSelector.PassePartoutSelector);
    }

    if (!pptElement) {
      return;
    }
    
    // on click set style
    pptElement.addEventListener('click', () => {
      this.setAddonStyles(pptElement.getAttribute('data-type'));
      // update price
      ProductSelector.updateTotalPrice(pptElement);
    }, true);
  }

  /**
   * Setup select product size events
   **/
  static setupSelectBoxProductSizeEvents(): void {
    const selectBoxes: NodeListOf<HTMLSelectElement> = document.querySelectorAll(ProductSelector.ProductSelectBoxSelector);

    // fetch initial accessories/addons
    const defaultSelectBox: HTMLSelectElement = selectBoxes.item(0);
    if (defaultSelectBox !== null) {
      ProductSelector.fetchAddons(+defaultSelectBox.value)
        .then(response => {
          const errorContainer: HTMLElement = document.querySelector('.js-product-selector-validation-errors');

          // if an error occurred
          if (response == null) {
            errorContainer.innerHTML = window.Printler.DefaultErrorMessage;
            return;
          }

          Utilities.log('Initial addons loaded');

          const addons = document.querySelector(ProductSelector.AddonsContainerSelector);
          addons.innerHTML = response;
        })
        .then(() => {
          // setup click events after dynamically added "add to cart buttons"
          Cart.setupAddToCartButtonsEvent();
        });
    }

    // setup change events
    selectBoxes.forEach(selectBox => {
      selectBox.addEventListener('change', () => {
        // disable select until addons has been fetched
        selectBox.setAttribute('disabled', 'true');

        // TODO: hide addons and show loader until loaded

        // fetch addons
        ProductSelector.fetchAddons(+selectBox.value)
          .then(response => {
            const errorContainer: HTMLElement = document.querySelector('.js-product-selector-validation-errors');

            // if an error occurred
            if (response == null) {
              errorContainer.innerHTML = window.Printler.DefaultErrorMessage;
              return;
            }
            const addons = document.querySelector(ProductSelector.AddonsContainerSelector);
            addons.innerHTML = response;
          })
          .then(() => {
            // setup click events after dynamically added "add to cart buttons"
            Cart.setupAddToCartButtonsEvent();

            // reactivate select when options loaded
            selectBox.removeAttribute('disabled');
          });
      });
    });
  }

  /**
   * Add event listener for addons select
   */
  static setupAddonsSelectChangeEvent(): void {
    const addonsSelect: Element = document.querySelector(ProductSelector.ProductAddonsSelector);

    addonsSelect?.addEventListener('change', this.handleAddonsSelectChange, true);
  }

  /**
   * Add event listener for margin switch
   * Set container to apply to a specific container
   */
  static setupMarginSwitchClickEvent(container: HTMLElement = null): void {
    let addonsContainerElement: HTMLElement = document.querySelector(ProductSelector.AddonsAndOptionsContainerSelector);

    if (container != null) {
      addonsContainerElement = container.querySelector(ProductSelector.AddonsAndOptionsContainerSelector);
    }

    if (!addonsContainerElement) {
      return;
    }

    const marginButtonGroup: HTMLElement = addonsContainerElement.querySelector(ProductSelector.ProductMarginToggleSelector);
    const marginOptions = marginButtonGroup.querySelectorAll('input[name$="options[Margin]"]');

    // loop and setup event listener for margin option buttons
    Array.from(marginOptions).forEach(opt => {
      opt.addEventListener('click', this.handleMarginClick, true);
    });
   }

  /**
   * Set current price
   * @param price - the price to set
   * @param type - the product type
   * @param radio - the element selected/clicked
   */
  static setPrice(price: string, type: string, radio: HTMLElement): void {
    if (!price) {
      return;
    }
    const wrapper = radio.closest('.js-product-selector');
    const currentProductSelector = radio.closest('.js-product-selector-form')
    const priceContainer: HTMLElement = wrapper.querySelector(this.HeadPriceSelector);
    const priceAtcContainer: HTMLElement = currentProductSelector.querySelector('.js-total-atc-price');

    /* 
     * If gallery wall page selector
     * - Update price and product id
    */
    if (document.querySelector('.js-gallery-wall')) {
      // no currency
      let priceValue = price.substr(0, price.indexOf(' ')); // expecting a currency in the price string

      if (!priceValue) {
        priceValue = price;
      }

      currentProductSelector.setAttribute('data-price-calculated', priceValue);
      currentProductSelector.setAttribute('data-product-id', radio.dataset.value);

      const productTitle = currentProductSelector.querySelector('.js-selected-product');

      productTitle.innerHTML = `Poster ${radio.dataset.description}`;

      GalleryWall.calculateTotalPrice();
    }

    if (!priceContainer || !priceAtcContainer) {
      return;
    }

    priceContainer.innerHTML = price;
    priceAtcContainer.innerHTML = price;
  }

  /**
   * Set sale price label if SiteDiscount is set
   */
  static setSalePrice(price: string, type: string, radio: HTMLElement): void {
    if (!price) {
      return;
    }

    const wrapper = radio.closest('.js-product-selector');
    const currentProductSelector = radio.closest('.js-product-selector-form');
    const priceSelector = '.js-product-selector-head-price-sale';

    const salePriceElement: HTMLElement = wrapper.querySelector(priceSelector);
    const atcSalePriceElement: HTMLElement = currentProductSelector.querySelector('.js-total-atc-price');

    if (!salePriceElement || !atcSalePriceElement) {
      return;
    }

    const currencyFormatted = window.Printler.PriceFormat.substring(1);

    if (currencyFormatted == '#163;0') { // custom fix for the pound symbol, dollar needs no converting
      salePriceElement.innerHTML = `£${price}`;
      atcSalePriceElement.innerHTML = `£${price}`;
    }
    else {
      salePriceElement.innerHTML = `${price}${currencyFormatted}`;
      atcSalePriceElement.innerHTML = `${price}${currencyFormatted}`;
    }
    
  }

  /**
   * Set addon price label
   * */
  static setAddonPrice(price: string, type: string): void {
    const priceContainer: HTMLElement = document.querySelector('.js-frame-addon-price-container');
    
    if (type == 'noFrame') {
      priceContainer.innerText = `${priceContainer.getAttribute('data-from-price')}`;
    }
    else {
      priceContainer.innerText = `${price}`;
    }
  }
  /**
   * Set addon original price label
   * */
  static setAddonOriginalPrice(price: string, type: string): void {
    const priceContainer: HTMLElement = document.querySelector('.js-frame-addon-original-price-container');

    if (type == 'noFrame') {
      priceContainer.innerText = `${priceContainer.getAttribute('data-from-price')}`;
    }
    else {
      priceContainer.innerText = `${price}`;
    }
  }

  /**
   * Update total price after addon has been added
   * @param elm - An HTMLELement to reference the closest product-selector
   * */
  static updateTotalPrice(elm: HTMLElement): void {
    const totalPriceContainer: HTMLElement = document.querySelector(this.HeadPriceSelector);
    const totalDiscountPriceContainer: HTMLElement = document.querySelector('.js-product-selector-head-price-sale');

    // product
    const productGroup = elm.closest('.js-product-tab-pane');

    // return if no prd group (wall item page)
    if (!productGroup) {
      return;
    }
    
    const currentProduct: HTMLElement = productGroup.querySelector('.js-product-group-btn:checked');

    // ATC
    const totalPriceATCContainer: HTMLElement = productGroup.querySelector('.js-total-atc-price'); // ATC button
    let totalPrice = +currentProduct.dataset.priceValue;
    let totalSalePrice = +currentProduct.dataset.discountPriceValue.replace(',', '.');

    // frame
    const frames = productGroup.querySelector(this.FrameGroupSelector);
    const selectedFrame: HTMLElement = frames.querySelector('.js-frame-group-btn:checked');

    if (selectedFrame) {
      const framePrice = +selectedFrame.dataset.priceValue;
      const frameOriginalPrice = +selectedFrame.dataset.originalPriceValue;
      
      if (framePrice) { // if not no-frame
        totalPrice += frameOriginalPrice;
        totalSalePrice += framePrice;
      }
    }

    // margins (passepartout)
    const margins = productGroup.querySelector(this.ProductMarginToggleSelector);
    const selectedMargin: HTMLElement = margins?.querySelector('input:checked');

    if (selectedMargin) {
      const marginPrice = +selectedMargin.dataset.priceValue;
      const marginOriginalPrice = +selectedMargin.dataset.originalPriceValue;

      totalPrice += marginOriginalPrice;
      totalSalePrice += marginPrice;
    }

    // addons
    const addonsDropdown = productGroup.querySelector(this.ProductAddonsSelector);
    const selectedAddon: HTMLElement = addonsDropdown?.querySelector('option:checked');

    if (selectedAddon) {
      const addonPrice = +selectedAddon.dataset.priceValue;
      const addonOriginalPrice = +selectedAddon.dataset.originalPriceValue;
      totalPrice += addonOriginalPrice;
      totalSalePrice += addonPrice;
    }

    // head price
    totalPriceContainer.innerHTML = `${Utilities.formatPrice(totalPrice, totalPriceContainer.dataset.currency)}`;

    // ATC price
    if (totalPriceATCContainer)
      totalPriceATCContainer.innerHTML = `${Utilities.formatPrice(totalPrice, totalPriceContainer.dataset.currency)}`;

    // Update with discounted price
    if (totalDiscountPriceContainer) {
      totalDiscountPriceContainer.innerHTML = `${Utilities.formatPrice(totalSalePrice, totalDiscountPriceContainer?.dataset.currency)}`;
      totalPriceATCContainer.innerHTML = `${Utilities.formatPrice(totalSalePrice, totalDiscountPriceContainer?.dataset.currency)}`;
    }
  }

  /**
   * Handle margin switch
   * @param e - click event
   */
  static handleMarginClick(e: Event): void {
    const marginInputElement = e.currentTarget as HTMLInputElement;
    const posterElement = ProductSelector.Poster;
    const mobilePosterElement = document.querySelector(ProductSelector.ArtworkSelector);

    // set/remove poster accessory css class
    if (marginInputElement.value === Margin.WithMargin) { // 'WithMargin'
      posterElement?.classList.add(Addons.Margin);
      mobilePosterElement?.classList.add(Addons.Margin);
    } else {
      posterElement?.classList.remove(Addons.Margin);
      mobilePosterElement?.classList.remove(Addons.Margin);
    }

    // always clear passepartout when changing margins
    posterElement?.classList.remove(Addons.Passpartout);
    mobilePosterElement?.classList.remove(Addons.Passpartout);

    // Do we need this? Margins free?
    ProductSelector.updateTotalPrice(marginInputElement);
  }

  /**
   * Apply styles to poster on addons select
   * @param e
   */
  static handleAddonsSelectChange(e: Event): void {
    e.preventDefault();

    const selectElm = e.currentTarget as HTMLSelectElement;
    const optionElm = selectElm[selectElm.selectedIndex];

    // set styles
    ProductSelector.setAddonStyles(optionElm.getAttribute('data-type'));

    // update total price
    ProductSelector.updateTotalPrice(selectElm);
  }

  /**
   * Set initial poster addon styles
   * @param premium - a flag that indicates if it's the Fine Art default values.
   */
  static setInitialPreviewValues(premium = false): void {
    const posterElement = ProductSelector.Poster;
    const mobilePosterElement = document.querySelector(ProductSelector.ArtworkSelector);

    // clear previous
    posterElement?.classList.remove(...ProductSelector.AddonsCssClasses);
    mobilePosterElement?.classList.remove(...ProductSelector.AddonsCssClasses);

    // add white margin default
    posterElement?.classList.add(Addons.Margin);
    mobilePosterElement?.classList.add(Addons.Margin);

    // add premium classes
    const premiumDefaultFrame = Addons.BlackFrame;

    if (premium) {
      posterElement?.classList.add(Addons.IsFineArt);
      mobilePosterElement?.classList.add(Addons.IsFineArt);
      posterElement?.classList.add(premiumDefaultFrame);
      mobilePosterElement?.classList.add(premiumDefaultFrame);
      posterElement?.classList.add(Addons.Passpartout);
      mobilePosterElement?.classList.add(Addons.Passpartout);
    } else {
      posterElement?.classList.remove(Addons.IsFineArt);
      mobilePosterElement?.classList.remove(Addons.IsFineArt);
      posterElement?.classList.remove(premiumDefaultFrame);
      mobilePosterElement?.classList.remove(premiumDefaultFrame);
      posterElement?.classList.remove(Addons.Passpartout);
      mobilePosterElement?.classList.remove(Addons.Passpartout);
    }
  }

  /**
   * Set addon styles on poster
   * @param addonType - the addon type string
   */
  static setAddonStyles(addonType: string, isPremium = false): void {
    if (!addonType) {
      // Non-styling addon type
      return;
    }

    const posterElement = ProductSelector.Poster;
    const mobilePosterElement = isPremium ? document.querySelector(ProductSelector.MobilePosterPremiumSelector) : document.querySelector(ProductSelector.ArtworkSelector);
    const frameAddonsList = [Addons.BlackFrame, Addons.WhiteFrame, Addons.OakFrame, Addons.WalnutFrame, Addons.RokokoFrame];

    if (addonType === AddonType.Passepartout) {
      // clear passepartout styles is handled by the margin switch event
      // add margin and passepartout styles if passepartout addon
      posterElement?.classList.add(Addons.Margin, Addons.Passpartout);
      mobilePosterElement?.classList.add(Addons.Margin, Addons.Passpartout);
      return;
    }

    // clear previous addons styles
    posterElement?.classList.remove(...frameAddonsList);
    mobilePosterElement?.classList.remove(...frameAddonsList);

    let cssClass;

    switch (addonType) {
      case AddonType.BlackFrame:
        cssClass = Addons.BlackFrame;
        break;
      case AddonType.WhiteFrame:
        cssClass = Addons.WhiteFrame;
        break;
      case AddonType.OakFrame:
        cssClass = Addons.OakFrame;
        break;
      case AddonType.WalnutFrame:
        cssClass = Addons.WalnutFrame;
        break;
      case AddonType.RokokoFrame:
        cssClass = Addons.RokokoFrame;
        break;
      case AddonType.Passepartout:
        cssClass = Addons.Passpartout;
        posterElement?.classList.add(Addons.Margin);
        mobilePosterElement?.classList.add(Addons.Margin);
        break;
      default:
        posterElement?.classList.remove(...frameAddonsList);
        mobilePosterElement?.classList.remove(...frameAddonsList);
    }

    if (cssClass) {
      posterElement?.classList.add(cssClass);
      mobilePosterElement?.classList.add(cssClass);
    }
  }

  /**
   * Fetch addons and options for given product and photo id.
   * @param productId - the product id
   * @param photoId - poster/photo id
   */
  static async fetchAddonsAndOptions(productId: number, photoId: number): Promise<string> {
    const requestOptions = {
      method: 'GET',
    };

    const requestBaseUrl = UrlService.getRenderUrl(ApiEndpoints.ProductAddonsAndOptions, {
      photoId: photoId,
      productId: productId,
    });

    const response = await fetch(requestBaseUrl, requestOptions);

    if (response.ok) {
      const html = await response.text();

      // push optimize change event 
      window.dataLayer.push({ 'event': 'optimize.activate.addons' });

      return html;
    } else {
      Utilities.log('Error fetching product options html');
      return null;
    }
  }

  /**
   * Fetch available addons for given product id
   * @param productId - product id
   */
  static async fetchAddons(productId: number): Promise<string> {
    const requestOptions = {
      method: 'GET',
    };

    const requestBaseUrl = UrlService.getRenderUrl(ApiEndpoints.ProductAddons, {
      productId: productId,
    });
    const response = await fetch(requestBaseUrl, requestOptions);

    if (response.ok) {
      const html = await response.text();

      // push optimize change event 
      window.dataLayer.push({ 'event': 'optimize.activate.addonsandoptions' });

      return html;
    } else {
      Utilities.log('Error fetching product options html');
      return null;
    }
  }

  /**
   * Check if accessory
   * @param selectContainerElement
   */
  static isAccessory(selectContainerElement: HTMLElement): boolean {
    return selectContainerElement.dataset.selectType === 'accessory';
  }

  /**
   * Setup datalayer details when changing size/variant
   * @param btn
   */
  static setupDatalayerDetails(btn: HTMLElement): void {
    const productDetailsElement: HTMLElement = document.querySelector(Ecommerce.ProductDetailsSelector);

    productDetailsElement.dataset.gtmProductVariant = btn.dataset.description;
    productDetailsElement.dataset.gtmProductPrice = btn.dataset.price;

    Ecommerce.pushProductDetailsImpression(productDetailsElement);
  }
}

export default ProductSelector;
